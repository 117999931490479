<template>
  <div class="wrapper">
      <div class="nav flex">
          <div class="nav-item" v-for="(item, index) in nav" :class="active==index&&'nav-active'" :key="index">
              {{item.name}}
          </div>
      </div>
      <div class="goods-list">
          <!--订单列表-->
        <div class="goods-item" @click="$router.push('/my/orderListBDSH/orderDetails')">
            <div class="state-box flex ai-c jc-sb">
                <div class="lb">下单时间：019-7-31</div>
                <div class="state">待付款</div>
            </div>
            <div class="goods-shop">
                <img src="/img/icon-shop.png" alt="">
                i团团云供应链
            </div>
            <div class="list">
                <div class="goods flex" v-for="(item, index) in 3" :key="index">
                    <img src="/img/goods.png" alt="">
                    <div class="">
                        <div class="flex ai-bl">
                            <div class="name line">南极人全棉床垫床褥1.8m床...</div>
                            <div class="price">
                                ¥189.<font>10</font>
                            </div>
                        </div>
                        <div class="flex ai-c jc-sb" style="margin-top:.1rem">
                            <div class="classification line">5件套（3刀1剪1刀座）</div>
                            <div class="nb">x1</div>
                        </div>
                        <div class="fw">7天无理由退换</div>
                    </div>
                </div>
            </div>
            <div class="subtotal flex ai-c jc-fd">
                <div class="subtotal-zl">共2件商品</div>
                <div class="subtotal-lb">需付款：</div>
                <div class="subtotal-price">¥278.<font>10</font></div>
            </div>
            <div class="btn-box flex ai-c jc-fd">
                <div class="btn">取消订单</div>
                <div class="btn">加入购物车</div>
                <div class="btn">确认付款</div>
            </div>
        </div>
      </div>
      <v-loveGoodsList />
      <div class="kong"></div>
      <div class="go-home flex ai-c jc-c">
          <div class="btn" @click="$router.push('/home')">返回商城</div>
      </div>
  </div>
</template>

<script>
import loveGoodsList from '@/components/loveGoodsList'
export default {
  components:{
      'v-loveGoodsList': loveGoodsList
  },
  props:{},
  data(){
    return {
        nav: [{
            name: '全部'
        },{
            name: '待支付'
        },{
            name: '待使用'
        },{
            name: '待收货'
        },{
            name: '已完成'
        }],
        active: 0
    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper{
    overflow: hidden;
}
.wrapper .nav{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: .8rem;
    background-color: #fff;
    z-index: 100;
}
.wrapper .nav .nav-item{
    position: relative;
    flex: 1;
    line-height: .8rem;
    text-align: center;
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #282828;
}
.wrapper .nav .nav-active{
    font-weight: bold;
    color: #F7532C;
}
.wrapper .nav .nav-active::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - .44rem);
    width: .88rem;
    height: .04rem;
    background: #F35E3A;
}
.goods-list {
    width: 100%;
    margin-top: 1rem;
    
}
.state-box{
    width: 100%;
    height: .73rem;
    border-bottom: 1px solid rgba(244, 244, 244, 1);
}
.state-box .lb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
    margin-left: .28rem;
}
.state-box .state{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F35E3A;
    margin-right: .28rem;
}
.goods-item{
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: .2rem;
    padding-bottom: .4rem;
}
.goods-item .goods-shop{
    font-size: .26rem;
    font-family: PingFang;
    font-weight: bold;
    color: #61626A;
    margin-left: .3rem;
    margin-top: .3rem;
}
.goods-item .goods-shop img{
    width: .22rem;
    height: .2rem;
    margin-right: .1rem;
}
.list{
    margin-top: .1rem;
}
.list .goods{
    width: 100%;
    box-sizing: border-box;
    padding: 0.3rem .3rem;
}
.list .goods img{
    width: 1.54rem;
    height: 1.54rem;
    margin-right: .3rem;
}
.list .goods .name{
    width: auto;
    /* width: 3.90rem; */
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-right: .16rem;
}
.list .goods .price{
    white-space: nowrap;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.list .goods .price font{
    font-size: .24rem;
}
.list .goods .classification{
    max-width: 3.5rem;
    height: .38rem;
    padding: 0 .05rem;
    line-height: .38rem;
    box-sizing: border-box;
    background: #F4F4F4;
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .nb{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.list .goods .fw{
    width: fit-content;
    height: .28rem;
    line-height: .28rem;
    padding: 0 0.05rem;
    background: #F9EFED;
    font-size: .20rem;
    font-family: PingFang;
    font-weight: 500;
    color: #F6593F;
    margin-top: .4rem;
}
.subtotal{
    margin-top: .5rem;
}
.subtotal-zl{
    font-size: .24rem;
    font-family: PingFang;
    font-weight: 500;
    color: #AAAAAA;
}
.subtotal-lb{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
    margin-left: .15rem;
}
.subtotal-price{
    font-size: .30rem;
    font-family: PingFang;
    font-weight: 800;
    color: rgba(40, 40, 40, 1);
    margin-right: .38rem;
}
.subtotal-price font{
    font-size: .28rem;
}
.btn-box {
    margin-top: .35rem;
}
.btn-box .btn{
    width: 1.7rem;
    height: 0.70rem;
    line-height: .7rem;
    text-align: center;
    background: #FDFEFF;
    border: .02rem solid #61626A;
    border-radius: .35rem;
    margin-right: .27rem;
    font-size: .28rem;
    font-family: PingFang;
    font-weight: 500;
    color: #61626A;
}
.btn-box .btn:nth-last-child(1){
    color: #F35E3A;
    border-color: #F35E3A;
}
.kong{
    width: 100%;
    height: 2rem;
}
.go-home{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.4rem;
    background-color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
    padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.go-home .btn{
    width: 6.90rem;
    height: .90rem;
    background: #F35E3A;
    border-radius: .45rem;
    font-size: .36rem;
    font-family: PingFang;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: .9rem;
}
</style>